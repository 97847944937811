export default [
  {
    header: 'Dashboard',
  },
  {
    title: 'HOME',
    route: 'dashboard-app-home',
    icon: 'HomeIcon'
  },
  {
    title: 'Invest',
    // route: 'apps-e-commerce-shop',
    route: 'nft-invest',
    icon: 'DollarSignIcon',
  },
  {
    title: 'Create Lease NFTs',
    route: 'create-lease-wizard',
    icon: 'FilePlusIcon',
  },
  {
    title: 'My Portfolio',
    route: 'nft-portfolio',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'Manage Lease NFTs',
    route: 'manage-leases',
    icon: 'EditIcon',
  }
  // {
  //   title: 'Manage Leases',
  //   route: 'pages-profile',
  //   icon: 'PaperclipIcon',
  //   children: [
  //     {
  //       title: 'Manage Lease NFTs',
  //       route: 'pages-profile',
  //       icon: 'EditIcon',
  //     },
  //     {
  //       title: 'Calendar',
  //       route: 'pages-profile',
  //       icon: 'CalendarIcon',
  //     }
  //   ]
  // }
]
