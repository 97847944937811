export default [
  {
    header: 'ADMIN',
  },
  // {
  //     title: 'Calendar',
  //     route: 'apps-calendar',
  //     icon: 'CalendarIcon',
  // },
  {
    title: 'Banking',
    route: 'pages-account-setting',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Bank Details',
        route: 'bank-details',
      },
      {
        title: 'Payment Methods',
        route: 'bank-paymentmethods',
      },
      {
        title: 'NFT Wallet',
        route: 'bank-wallets',
      },
    ]
  },
  {
    title: 'Billing',
    route: 'pages-account-setting',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Plan',
        route: 'pages-pricing',
      },
      {
        title: 'Invoices',
        route: 'apps-invoice-list',
      },
      {
        title: 'Revenue Reports',
        route: 'apps-invoice-list',
      },
    ]
  },
  {
    title: 'Settings',
    route: 'pages-account-setting',
    icon: 'SettingsIcon'
  },
  {
    title: 'Support',
    icon: 'LifeBuoyIcon',
    children: [
      {
        title: 'Faq',
        route: 'pages-faq',
      },
      {
        title: 'Knowledge Base',
        route: 'pages-knowledge-base',
      },
      {
        title: 'Contact',
        route: 'pages-knowledge-base',
      },
    ],
  }
]
